<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <nav-tabs-card>
        <template slot="content">
          <md-tabs class="md-success" md-sync-route>
            <md-tab
              id="tab-cadastro"
              md-label="Cadastro"
              md-icon="account_box"
              v-model="cadastro"
              :to="{ name: 'Cadastro Pensionista' }"
              :md-active="true"
            >
              <!--<md-tab
              id="tab-cadastro"
              md-label="Cadastro"
              md-icon="account_box"
              v-model="cadastro"
              :to="{ name: 'Cadastro' }"
              @md-selected="onSelect"
              :md-active="true"
            > -->
            </md-tab>
            <md-tab
              id="tab-pensao"
              md-label="Pensao"
              md-icon="monetization_on"
              v-model="pensao"
              :to="{ name: 'Pensao' }"
              :md-active="false"
            >
            </md-tab>
            <md-tab
              id="tab-enderecos"
              md-label="Endereços"
              md-icon="apartment"
              v-model="enderecos"
              :to="{ name: 'Enderecos Pensionista' }"
              :md-active="false"
            >
            </md-tab>
            <md-tab
              id="tab-telefones"
              md-label="Telefones"
              md-icon="call"
              v-model="telefones"
              :to="{ name: 'Telefones Pensionista' }"
              :md-active="false"
            >
            </md-tab>
            <md-tab
              id="tab-emails"
              md-label="Emails"
              md-icon="mail"
              v-model="emails"
              :to="{ name: 'Emails Pensionista' }"
              :md-active="false"
            >
            </md-tab>
            <md-tab
              id="tab-arquivos"
              md-label="Arquivos"
              md-icon="description"
              v-model="arquivos"
              :to="{ name: 'Arquivos Pensionista' }"
              :md-active="false"
            >
            </md-tab>
          </md-tabs>
          <!--<div style="display:flex;margin-left:83%;" z-index="100000">
            <md-switch v-model="editionMode">Modo Edição </md-switch>
          </div> -->
          <!--<div id="container">
            <div id="right">
              <md-switch v-model="editionMode">Modo Edição </md-switch>
            </div>
          </div>-->

          <router-view
            :isEdition="editionMode"
            :tipoProprietario="tipoProprietario"
            v-on:edition="isSwitchOn"
          ></router-view>
        </template>
      </nav-tabs-card>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  props: {
    id: {
      type: String
    }
  },
  components: { NavTabsCard },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      tipoProprietario: "PENSIONISTA",
      cadastro: null,
      pensao: null,
      enderecos: null,
      telefones: null,
      emails: null,
      editionMode: false,
      arquivos: null,
      mensagem: "",
      papel: ""
    };
  },
  beforeMount() {
    this.getPapel();
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    async loadPensionistaTab() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.idPensionista
      ) {
        window._Vue.$router
          .push({ name: `Cadastro Pensionista` })
          .catch(err => {});
      } else {
        let id = this.id;
        let papel = this.papel;
        if (
          id !== "cadastro" &&
          id !== "enderecos" &&
          id !== "telefones" &&
          id !== "emails" &&
          id !== "pensao" &&
          id !== "arquivos"
        ) {
          window._Vue.$router
            .push({ name: `Cadastro Pensionista` })
            .catch(err => {});
        } else {
          Swal.fire({
            title: "ATENÇÃO!",
            icon: "warning",
            text: "Por favor selecione um Pesionista antes!",
            customClass: {
              confirmButton: "md-button md-warning btn-fill"
            },
            buttonsStyling: false
          });
          window._Vue.$router.push({ name: "Pensionistas" }).catch(err => {});
        }
      }
    },
    isSwitchOn(value) {
      this.editionMode = value;
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    onSelect() {
      console.log("item");
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  mounted() {
    this.loadPensionistaTab();
  }
};
</script>
<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

::v-deep .md-tabs-navigation {
  padding: 15px 5px 15px 5px;
}

::v-deep .md-tabs-navigation .md-button {
  max-width: 100%;
}

#container {
  width: 450px;
  background-color: #eee;
  position: relative;
  margin-left: 10%;
}

#right {
  width: 200px;
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#left {
  width: 200px;
  border: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>
